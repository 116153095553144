import axios from 'axios';
import BaseAPIClient from '@/services/baseClient';
import {RUN_TIMINGS, WEEKEND_TYPES} from '@/helpers/dataLabels';

export class ApiClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();

    this.baseURL = process.env.NODE_ENV === 'production' ? '/api/v1' : process.env.VUE_APP_API_URI;
    this.instance.defaults.baseURL = this.baseURL;
  }

  async reportError(message) {
    return this.instance.post('error', {message});
  }

  async authenticate(user) {
    return this.instance.post('authentications/authenticate', user);
  }

  async validateOTP(query) {
    return this.instance.post('authentications/validateOTP', query);
  }

  async search(query) {
    return this.instance.post('/queries/aml-status', query);
  }

  async updateStatus(ids, status, isPkyc) {
    return this.instance.put(`${isPkyc ? '/pkyc' : ''}/query-log`, { ids, status });
  }

  async geoSearch(query) {
    return this.instance.post('/queries/aml', query);
  }

  async getDataSources(filterDataSources = true) {
    return this.instance.get(`/summary-reports/current?filterDataSources=${filterDataSources}`);
  }

  async getCountries() {
    return this.instance.get('/countries');
  }

  async reasignWorkflow(formData) {
    return this.instance.post('/user-management/reasign_workflow', formData);
  }

  async getEntity(id) {
    return this.instance.get(`/entities/${id}`);
  }

  async recognizeImage(file) {
    const formData = new FormData();
    formData.append('image', file);

    return this.instance.post('/vision/text', formData);
  }

  async getQueryLog(tenantId, params) {
    return this.instance.get(`/queries/${tenantId}`, { params });
  }

  async getPkycLog(tenantId, params) {
    return this.instance.get(`/pkyc/queries/${tenantId}`, { params });
  }

  async getPkycTenants(){
    return this.instance.get('/tenant-management/tenants/pkyc');
  }

  async getQueryLogDetails(searchParam) {
    if (
        !['_id', 'paymentResourceId', 'reference', 'amount'].includes(Object.keys(searchParam)[0]) ||
        searchParam[Object.keys(searchParam)[0]].trim().length === 0
    ) {
      throw new Error('Invalid search param');
    }
    return this.instance.post('/queries/details', {searchParam});
  }

  async getPkycDetails(id) {
    return this.instance.post('/queries/pkyc/details', { id });
  }

  async getUsageReport(params) {
    return this.instance.get('/reports/daily', {
      params,
      transformResponse: axios.defaults.transformResponse,
    });
  }

  async getSummaryReport(id) {
    if (id) {
      return this.instance.get(`/summary-reports/${id}`);
    }
    return this.instance.get('/summary-reports/current');
  }

  async getSummaryReports() {
    return this.instance.get('/summary-reports');
  }

  async getNewSummaryReports() {
    return this.instance.get('/summary-reports-new');
  }

  async getTenantDataSources(tenantId, type) {
    return this.instance.get(`/tenant-management/${tenantId}/${type}/tenant-data-sources`);
  }

  async addSourceName(tenantId, availableSources) {
    return this.instance.put(`/tenant-management/tenants/${tenantId}/add-available-sources`, { availableSources });
  }

  async getSdnUpdates() {
    return this.instance.get('/sdn-updates');
  }

  // ### USERS SECTION
  async getUsers() {
    return this.instance.get('/user-management/users');
  }

  async getUser(username) {
    return this.instance.get(`/user-management/users/${username}`);
  }

  async getUsersByRole(role) {
    return this.instance.get(`/user-management/byrole/${role}`);
  }

  async switchSupervisor(formData) {
    return this.instance.post('/user-management/switch_supervisor', formData);
  }

  async moveAnalyst(formData) {
    return this.instance.post('/user-management/move_analyst', formData);
  }

  async addUser(user) {
    return this.instance.post('/user-management/users', user);
  }

  async updateUser(update) {
    const { username } = update;
    return this.instance.put(`/user-management/users/${username}`, update);
  }

  async deleteUser(user) {
    const { username } = user;
    return this.instance.delete(`/user-management/users/${username}`);
  }

  async getDeletedUsers() {
    return this.instance.get('/user-management/deleted-users');
  }

  async changePassword(username, sessionId, password, newPassword) {
    if (!sessionId) {
      throw new Error('Missing sessionId');
    }

    const body = {
      password,
      newPassword,
    };

    if (sessionId && sessionId !== 'undefined') {
      body.sessionId = sessionId;
    }

    return this.instance.patch(`/user-management/users/${username}/password`, body);
  }

  async passwordsReset(tenantId){
    return this.instance.post(`/tenant-management/tenants/${tenantId}/require-passwords-reset`);
  }

  async changeUserSettings(username, settings) {
    return this.instance.patch(`/user-management/users/${username}/settings`, {
      settings,
    });
  }

  async restoreDeletedUser(username) {
    return this.instance.put(`/user-management/users/${username}`);
  }

  async getUserActions(fromDate,toDate) {
    return this.instance.get(`/user-management/actions?fromDate=${fromDate}&toDate=${toDate}`);
  }
  // ### TENANTS SECTION
  async getTenants() {
    return this.instance.get('/tenant-management/tenants');
  }

  async getTenant(tenantid) {
    return this.instance.get(`/tenant-management/tenant?tenantId=${tenantid}`);
  }

  async getTenantSearchConfiguration(tenantid, solution) {
    return this.instance.get(`/tenant-management/search-configutartions/${tenantid}?solution=${solution}`);
  }

  async updateSearchConfigurations(tenantid, solution, listForUpdate) {
    return this.instance.put(`/tenant-management/search-configutartions/${tenantid}?solution=${solution}`, {
      listForUpdate,
    });
  }

  async addTenant(tenant) {
    return this.instance.post('/tenant-management/tenants', tenant);
  }

  async updateTenant(update) {
    const { id } = update;
    return this.instance.put(`/tenant-management/tenants/${id}`, update);
  }

  async deleteTenant(tenantid) {
    return this.instance.delete(`/tenant-management/tenants/${tenantid}`);
  }

  async getDeletedTenant() {
    return this.instance.get('/tenant-management/deleted-tenants');
  }

  async getCustomCountries(tenantId) {
    return this.instance.get(`/tenant-management/custom-countries/${tenantId}`);
  }

  async setCustomCountries(tenantId, file) {
    return this.instance.post(`/tenant-management/custom-countries/${tenantId}`, file);
  }

  async deleteCustomCountries(tenantId) {
    return this.instance.delete(`/tenant-management/custom-countries/${tenantId}`);
  }

  async getDailyReport(solution, pkycType, tenantId, date, resultsIncluded) {
    if (pkycType === 'Daily') {
      return this.instance.get(`/report/${solution}/${tenantId}/${date}/${resultsIncluded}`);
    }

    return this.instance.get(`/report/${solution}/${tenantId}/${date}/${resultsIncluded}?pkycType=${pkycType}`);
  }

  async getAchFilesReport({ startDate, endDate, status, tenantId }) {
    return this.instance.get(`/report/ach?status=${status}&startDate=${startDate}&endDate=${endDate}&tenantId=${tenantId}`);
  }

  async compareNames(source, target, isIndividual, phoneticThreshold, distanceThreshold) {
    return this.instance.post('/names/compare', {
      nameA: source,
      nameB: target,
      isIndividual,
      phoneticThreshold,
      distanceThreshold,
      showFullResults: true,
    });
  }

  async getSessionsByTenantId(tenantid) {
    return this.instance.get(`/sessions/${tenantid}`);
  }

  // Persistence actions
  async getPersistence({tid, skip, limit, name, solution}){        
    return await this.instance.get(`persistence/${tid}/${solution}?limit=${limit}&skip=${skip}&name=${name}`);
  }

  async softResetPersistence(tenantId, namesToReset, solution){
    return await this.instance.post('persistence/softReset', {tenantId, namesToReset, solution});
  }

  async hardResetPersistence(tenantId, persistenceDate, solution){    
    return await this.instance.post('persistence/hardReset', {tenantId, persistenceDate, solution});
  }

  logout = async (userToken, isForceLogout = false, message) => {
    if (userToken) {
      const headers = {};
      headers['x-access-token'] = userToken;
      return this.instance.post(`authentications/logout?isForceLogout=${isForceLogout}`, { message }, { headers });
    }
    return this.instance.post('authentications/logout', { message });
  };

  async adverseSearch(searchFor) {
    return this.instance.post('/adverse/search/', { searchFor });
  }

  async openDocs() {
    const url = `${this.baseURL}/docs`;
    window.open(url, 'blank');
  }

  async getVAData(type, id,source) {
    return await this.instance.get(`query/${type}/${id}/${source}`);
  }

  async addPkycTiming(params) {
    const {runTiming, weekendType} = params;
    if (
      !runTiming ||
      !RUN_TIMINGS.includes(runTiming) ||
      (weekendType && !WEEKEND_TYPES.includes(weekendType))
    ) {
      throw new Error('Invalid params');
    }

    return this.instance.post('/tenant-management/add-pkyc-timing', params);
  }

  async getPkycNextRun(tenantId) {
    return this.instance.get(`/tenant-management/pkyc/${tenantId}`);
  }

  async getAllCountriesLists() {
    return this.instance.get('/countries-lists');
  }

  //TF section

  async searchTf(query) {
    return this.instance.post('/tf/manual', query);
  }

  async uploadTF360File({ file }) {
    const formData = new FormData();
    formData.append('letter', file);

    return this.instance.post('tf/letter-360',formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async uploadTF799File({ file }) {
    const formData = new FormData();
    formData.append('letter', file);

    return this.instance.post('tf/letter-799',formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async getActivePositions(tenantId, filters) {
    let requestUrl = `/positions/${tenantId}?`;

    if (filters.role) requestUrl += `role=${filters.role}`;
    return this.instance.get(requestUrl);
  }

  async getTeamsData(tenantId) {
    return this.instance.get(`/${tenantId}/teams/data`);
  } 

  async setUserTeamInSession(username, teamId) {
    return this.instance.post('/session/set-team', { username, teamId });
  } 
}

export default new ApiClient();
