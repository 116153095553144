import store from '@/store';
import { createRouter, createWebHistory } from 'vue-router';
import {USER_ROLE_KEY, SELECT_TEAM_KEY, USER_TOKEN_KEY} from '@/helpers/localStorageLabels';

import ActionRoles from '@/helpers/actionRoles';
// import { cancelAxiosRequest } from '@/helpers/axiosAbort';

import { AUTH_LOGOUT } from '@/store/actions/auth';

import batchRoutes from './batchRoutes';

const isAdmin = (to, from, next) => {
  const userRole = localStorage.getItem(USER_ROLE_KEY);
  if (ActionRoles.adminRoles.includes(userRole)) {
    next();
    return;
  }
  next('/login');
};

const isAdminOrBSA = (to, from, next) => {
  const userRole = localStorage.getItem(USER_ROLE_KEY);
  if (ActionRoles.adminRoles.includes(userRole) || ActionRoles.bsaRoles.includes(userRole)) {
    next();
    return;
  }
  next('/login');
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/StartUp.vue'),
  },
  {
    path: '/supervision/team-select',
    name: 'team-select',
    component: () => import('@/views/TeamSelectView.vue'),
  },
  {
    path: '/manual-search',
    name: 'querySearch',
    component: () => import('@/views/aml/AmlSearchView.vue'),
  },
  {
    path: '/tf-search',
    name: 'tfSearch',
    component: () => import('@/views/tf/TfSearchView.vue'),
  },
  {
    path: '/query-log',
    name: 'queryLog',
    component: () => import('@/views/QueryLog.vue'),
  },
  {
    path: '/ach/query-log',
    name: 'achQueryLog',
    component: () => import('@/views/QueryLog.vue'),
  },
  {
    path: '/pkyc/query-log',
    name: 'pkycQueryLog',
    component: () => import('@/views/QueryLog.vue'),
  },
  {
    path: '/aml/query-log',
    name: 'amlQueryLog',
    component: () => import('@/views/QueryLog.vue'),
  },
  {
    path: '/tf/auto-log',
    name: 'tfAutoLog',
    component: () => import('@/views/QueryLog.vue'),
  },
  {
    path: '/tf/360-log',
    name: 'tf360Log',
    component: () => import('@/views/QueryLog.vue'),
  },
  {
    path: '/tf/799-log',
    name: 'tf799Log',
    component: () => import('@/views/QueryLog.vue'),
  },
  {
    path: '/tf/manual-log',
    name: 'tfManualLog',
    component: () => import('@/views/QueryLog.vue'),
  },
  {
    path: '/compare-names',
    name: 'compareNames',
    component: () => import('@/views/NamesComparisonView'),
  },
  {
    path: '/ach-upload',
    name: 'achUpload',
    component: () => import('@/views/UploadView.vue'),
  },
  {
    path: '/pkyc-upload',
    name: 'pkycUpload',
    component: () => import('@/views/UploadPkycView.vue'),
  },
  {
    path: '/tf360-upload',
    name: 'tf360Upload',
    component: () => import('@/views/UploadTfView.vue'),
  },
  {
    path: '/tf799-upload',
    name: 'tf799Upload',
    component: () => import('@/views/UploadTfView.vue'),
  },
  {
    path: '/supervision/wired/queries',
    name: 'querylogs',
    component: () => import('@/views/WiredQuerylogsView.vue'),
  },
  {
    path: '/querylog',
    name: 'querylogData',
    component: () => import('@/views/QuerylogDataView.vue'),
    props: true,
  },
  {
    path: '/pkyc/querylog',
    name: 'pkycQuerylogData',
    component: () => import('@/views/QuerylogDataView.vue'),
    props: true,
  },
  {
    path: '/supervision/wire/tables',
    name: 'wireQuerylogs',
    component: () => import('@/views/WiredQuerylogsView.vue'),
  },
  {
    path: '/supervision/wire/dashboard',
    name: 'wireDashboard',
    component: () => import('@/views/Dashboards/WireDashboardView.vue'),
  },
  {
    path: '/supervision/tf360/tables',
    name: 'tf360Querylogs',
    component: () => import('@/views/TfaQuerylogsView.vue'),
  },
  {
    path: '/supervision/tf360/dashboard',
    name: 'tf360Dashboard',
    component: () => import('@/views/Dashboards/TfaDashboardView.vue'),
  },
  {
    path: '/supervision/tf799/tables',
    name: 'tf799Querylogs',
    component: () => import('@/views/TfaQuerylogsView.vue'),
  },
  {
    path: '/supervision/tf799/dashboard',
    name: 'tf799Dashboard',
    component: () => import('@/views/Dashboards/TfaDashboardView.vue'),
  },
  {
    path: '/:type/dashboard/:finalStatus',
    name: 'doughnutTableView',
    component: () => import('@/views/DoughnutTableView.vue'),
    props: true,
  },
  {
    path: '/supervision/ach/tables',
    name: 'achQuerylogs',
    component: () => import('@/views/AchQuerylogsView.vue'),
  },
  {
    path: '/supervision/ach/dashboard',
    name: 'achDashboard',
    component: () => import('@/views/Dashboards/AchDashboardView.vue'),
  },
  {
    path: '/supervision/pkyc/tables',
    name: 'pkycQuerylogs',
    component: () => import('@/views/PkycQuerylogsView.vue'),
  },
  {
    path: '/supervision/pkyc/dashboard',
    name: 'pkycDashboard',
    component: () => import('@/views/Dashboards/PkycDashboardView.vue'),
  },
  {
    path: '/supervision/aml/tables',
    name: 'amlQuerylogs',
    component: () => import('@/views/AmlQuerylogsView.vue'),
  },
  {
    path: '/reports/daily',
    name: 'dailyReportsView',
    component: () => import('@/views/reports/DailyReportsView.vue'),
  },
  {
    path: '/reports',
    name: 'sysAdminReportsView',
    component: () => import('@/views/reports/SysAdminReportsView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/UserProfile'),
  },
  {
    path: '/setpassword',
    name: 'setpassword',
    component: () => import( '@/views/SetPassword'),
  },
  {
    path: '/admin/user-management',
    name: 'userManagement',
    component: () => import('@/views/UserManagement'),
  },
  {
    path: '/admin/user-audit',
    name: 'userAudit',
    component: () => import('@/views/UserAudit'),
  },
  {
    path: '/admin/system-configuration',
    name: 'systemConfiguration',
    component: () => import('@/views/SystemConfiguration'),
  },
  {
    path: '/supervision/users',
    name: 'chiefUserDashboard',
    component: () => import('@/views/ChiefUserDashboard'),
  },
  {
    path: '/admin/persistence',
    name: 'persistence',
    component: () => import('@/views/PersistenceView'),
  },
  {
    path: '/admin/summary-reports',
    name: 'summaryReports',
    component: () => import('@/views/admin/SummaryReports'),
  },
  {
    path: '/admin/available-sources',
    name: 'availableSources',
    component: () => import('@/views/admin/AvailableSources'),
  },
  {
    path: '/admin/sdn-updates',
    name: 'sdnUpdates',
    component: () => import('@/views/admin/SdnUpdates'),
  }, 
  {
    path: '/admin/custom-sources',
    name: 'customSources',
    component: () => import('@/views/admin/CustomSources'),
  },
  {
    path: '/admin/custom-countries',
    name: 'customCountries',
    component: () => import('@/views/admin/CustomCountries'),
  },
  {
    path: '/admin/custom-accounts',
    name: 'customAccounts',
    component: () => import('@/views/admin/CustomAccounts'),
  },
  {
    path: '/admin/data-sources',
    name: 'dataSources',
    component: () => import('@/views/admin/DataSources'),
  },
  {
    path: '/admin/tenant-management',
    name: 'tenantManagement',
    component: () => import('@/views/TenantManagement'),
    beforeEnter: isAdmin,
  },
  {
    path: '/admin/tenant-audit',
    name: 'tenantAudit',
    component: () => import('@/views/TenantAuditView'),
    beforeEnter: isAdmin,
  },
  {
    path: '/admin/system-control',
    name: 'systemControl',
    component: () => import('@/views/SystemControl'),
    beforeEnter: isAdmin,
  },
  {
    path: '/admin/pkyc-persistence',
    name: 'pkycPersistence',
    component: () => import('@/views/PersistenceView'),
    beforeEnter: isAdminOrBSA,
  },
  {
    path: '/admin/ach-persistence',
    name: 'achPersistence',
    component: () => import('@/views/PersistenceView'),
    beforeEnter: isAdminOrBSA,
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    meta: { external: true, url: 'https://fincom.co/wp-admin/?wtlwp_token=8b5bbba73ec199f8d622d92c0c35377ef005ae836520304a8b04d9f58b654de4089440f51f7ca3e39c25028ba5009b1df28ed97becc7d2403999cd20bda40a21' },
  },
  ...batchRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.external) {
    // Open the external URL in a new tab
    window.open(to.meta.url, '_blank');
    // Prevent further navigation
    next(false);
    return;
  }
  const { isAuthenticated, passwordResetRequired, passwordExpired} = store.getters;

  const token =  sessionStorage.getItem(USER_TOKEN_KEY); 
  const user_role = localStorage.getItem(USER_ROLE_KEY); // Need for check that another tab did not cleared loacl starage
  if((!token || !user_role) && to.path != '/login') {
    store.dispatch('AUTH_LOGOUT', { emptyToken: true, messageForLogout: 'Your session has expired' });
    return next('/login');
  }

 // Check if SELECT_TEAM_KEY is set in localStorage and route is not already /supervision/team-select
  if (localStorage.getItem(SELECT_TEAM_KEY) !== null && !to.path.includes('/supervision/team-select')) {
    // Redirect to team selection page
    return next('/supervision/team-select');
  }
  // Redirect for wrong pages, previous redirect with pathMatch(*) does not work
  if (router.options.routes.find(item => item.name == to.name) == undefined) {
    next('/');
  } else if (to.path == '/login' || to.path == '/setpassword' || isAuthenticated) {
    if ((passwordResetRequired || passwordExpired) && to.path !== '/setpassword') {
      next('/setpassword');
    } else if (to.path == '/login' && isAuthenticated) {
      next('/');
    } else {
      next();
    }
  } else {
    next('/login');
  }
});

export default router;
