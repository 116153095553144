const Labels = {
  OPEN: 'open',
  REOPENED: 'reopened',
  PENDING: 'pending',
  SUBMITTED: 'submitted',
  CLOSED: 'closed',
  PERSISTENCE: 'persistence',
};

const labelsList = Object.values(Labels);

const autoClosed = 'Auto Closed';
const autoVerified = 'Auto verified';
const PersistenceClosed = 'closed by persistence';
const noAnalysts = 'Supervisor';

const SOLUTIONS = {
  aml: 'aml',
  wire: 'wire',
  ach: 'ach',
  pkyc: 'pkyc',
  tf360: 'tf360',
  tf799: 'tf799',
  pi_a: '314a',
};

const PKYC_TYPES = {
  customers: 'customer',
  employees: 'employee',
  vendors: 'vendor',
};

const STATUSES = {
  ALERTED: 'alerted',
  BLOCKED: 'blocked',
  FLAGGED: 'flagged',
  VERIFIED: 'verified',
};

const RUN_TIMINGS = ['monthly', 'daily', 'weekly'];
const WEEKEND_TYPES = ['first weekend', 'last weekend'];

const DASH_DASH_EMPTY_VALUE = '---';

export { Labels, labelsList, PersistenceClosed, noAnalysts, autoClosed, autoVerified, SOLUTIONS, PKYC_TYPES, STATUSES, DASH_DASH_EMPTY_VALUE, RUN_TIMINGS, WEEKEND_TYPES };
